import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Squat Snatch to a 1RM`}</p>
    <p>{`OHS to a 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`“Isabel”`}</p>
    <p>{`30 Snatches for time. (135/95)`}</p>
    <p><em parentName="p">{`*`}{`compare to 2/10/20.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      